<template>
  <div class="address">
    <div class="address-bg">
      <div class="address-total">
        <p>共{{ addresstotal }}个地址</p>
      </div>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="queryaddress"
        :offset="50"
        :immediate-check="false"
      >
        <div
          class="coupon-border"
          v-for="(item, index) in addressList"
          :key="index"
          @click.stop="onChangeAddress(item)"
        >  
          <!-- <div class="checkBoxLayout"> -->
            <!-- <van-checkbox v-model="item.checked" @click="onChangeAddress(item)" /> -->
          <!-- </div> -->
          
          <div class="coupon-content">
            <div class="coupon-body">
              <p class="couupon-city">
                <!-- {{ item.privinceName + item.cityName + item.districtName }} -->
              </p>
              <p class="coupon-name">{{ item.detail }}</p>
              <p class="coupon-phone">
                <span class="coupon-consignee">{{ item.consignee }}</span>
                <span class="coupon-time">{{ item.phone }}</span>
              </p>
              <!-- <div class="coupon-btn" v-show="item.type === 2">
            <button>默认</button>
          </div> -->
              <!-- <div class="coupon-btn coupon-btn-delete">
            <button @click.stop="onDeleteAddress(item)">删除</button>
          </div>
          <div class="coupon-btn coupon-btn-edit">
            <button @click.stop="onEditAddress(item)">编辑</button>
          </div> -->
            </div>
          </div>
        </div>
      </van-list>
      <div>
        <!-- <div class="quit">
        <button class="btn" @click.stop="add">添加收货地址</button>
      </div> -->
      </div>
      <van-loading size="24px" v-show="Alladdress" vertical
        >加载中...</van-loading
      >
    </div>
  </div>
</template>
<script>
import { addressList, deleteAddress } from "./service";
import { Dialog, Toast } from "vant";

export default {
  data() {
    return {
      addressList: [],
      finished: false,
      loading: false,
      forChangeAddress: false,
      Alladdress: false,
      checked: false,
      addresstotal: "",
      currentPage: 1,
    };
  },
  computed: {},
  mounted() {
    this.address(1);
  },
  methods: {
    add() {
      if (this.forChangeAddress) {
        this.$router.push(`/addressAdd?page=placeorder1`);
      } else {
        this.$router.push(`/addressAdd`);
      }
    },
    async address(currentPage) {
      this.Alladdress = true;
      const res = await addressList({
        "qp-objId-eq": "1385867852069736451",
        currentPage: currentPage,
      });
      this.Alladdress = false;
      if (res.status === "0") {
        if (res.data.items.length < 10) {
          this.finished = true;
        }
        this.addressList = res.data.items;
        this.addresstotal = res.data.total;
        this.currentPage = Number(res.data.page);
        this.loading = false;
      } else {
        this.loading = false;
        this.finished = true;
        Toast.fail(res.msg);
      }

      if (this.$route?.query?.page === "placeorder") {
        this.forChangeAddress = true;
      }
    },
    async queryaddress() {
      this.loading = true;
      if (!this.finished) {
        this.currentPage = this.currentPage + 1;
      }
      const res = await addressList({
        "qp-objId-eq": "1385867852069736451",
        currentPage: this.currentPage,
      });
      if (res.status === "0") {
        if (res.data.items.length < 10) {
          this.finished = true;
        }
        this.currentPage = Number(res.data.page);
        this.addressList = this.addressList.concat(res.data.items);
        this.addresstotal = res.data.total;
        this.loading = false;
      } else {
        this.loading = false;
        this.finished = true;
        Toast.fail(res.msg);
      }
    },

    async onChangeAddress(item) {
      // this.addressList = JSON.parse(
      //   JSON.stringify(
      //     this.addressList.map((d) => {
      //       if (d.id === item.id) {
      //         d.checked = true;
      //       } else {
      //         d.checked = false;
      //       }
      //       return d;
      //     })
      //   )
      // );

      if (this.forChangeAddress) {
        this.$router.push(`/placeorder1?addressId=${item.id}`);
      }
    },
    onEditAddress(item) {
      if (this.forChangeAddress) {
        this.$router.push(`/addressEdit?addressId=${item.id}&page=placeorder`);
      } else {
        this.$router.push(`/addressEdit?addressId=${item.id}`);
      }
    },
    onDeleteAddress(item) {
      Dialog.confirm({
        title: "删除地址",
        message: "请确认是否删除此地址？",
        theme: "round-button",
      }).then(() => {
        deleteAddress(item.id).then((res) => {
          if (res.status === "0") {
            this.address();
          }
        });
      });
    },
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
</style>
<style lang="less" scoped>
.address {
  background: #f5f6f7;
  width: 100%;
  height: 100%;
  p {
    margin: 0;
    padding: 0;
  }
  .address-bg {
    padding: 16px 16px 0px 16px;
    .address-total {
      p {
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        color: #96979b;
        margin: 0;
        padding: 0;
      }
    }
   /deep/ .coupon-border {
     padding: 16px;
      display: flex;
      justify-content: space-between;
      background: #ffffff;
      height: 112px;
      border-radius: 12px;
      margin-top: 12px;
      // .checkBoxLayout{
      //   width: 38px;
      //   display: flex;
      //   align-items: center;
      //   .van-checkbox {
      //     width: 38px;
      //   }
      //   .van-icon {
      //     left: 16px;
      //   }
      //   .van-checkbox__icon--checked .van-icon {
      //     background: #0095da;
      //   }
      // }
      .coupon-content {
        padding-right: 16px;
        -webkit-box-align: center;
        -webkit-align-items: center;
        align-items: center;
        box-sizing: border-box;
        min-height: 84px;
        color: #666666;
      }
      .coupon-body {
        // position: relative;
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        flex: 1;
        border-radius: 0 8px 8px 0;
        .couupon-city {
          // padding-top: 16px;
          font-size: 16px;
          font-weight: 500;
          text-align: left;
          color: #000000;
          line-height: 20px;
        }
        .coupon-name {
          color: #000;
          padding-top: 4px;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          overflow: hidden;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          width: 75vw;
        }
        .coupon-phone {
          font-size: 12px;
          color: #96979b;
          padding: 12px 0 16px 0;
          .coupon-consignee {
            margin-right: 16px;
          }
          .coupon-time {
            font-family: "Montserrat", sans-serif;
          }
        }
        .coupon-btn {
          // position: absolute;
          // right: 120px;
          // bottom: 13px;

          // button {
          //   width: 40px;
          //   height: 24px;
          //   font-family: "微软雅黑", sans-serif;
          //   font-weight: 400;
          //   font-style: normal;
          //   border-radius: 5px;
          //   text-align: center;
          //   border: 1px solid rgba(245, 108, 108, 1);
          //   background-color: rgba(245, 108, 108, 0);
          //   font-size: 12px;
          //   color: #f56c6c;
          //   line-height: 24px;
          // }
        }

        .coupon-btn-edit {
          position: absolute;
          right: 20px;
          bottom: 13px;

          button {
            border: 1px solid blueviolet;
            color: blueviolet;
          }
        }

        .coupon-btn-delete {
          position: absolute;
          right: 70px;
          bottom: 13px;

          button {
            border: 1px solid rgba(245, 108, 108, 1);
          }
        }
      }
    }
    .quit {
      display: flex;
      align-items: center;
      margin-top: 300px;
      .btn {
        width: 96%;
        border: 0;
        margin: 0 auto;
        font-size: 16px;
        height: 38px;
        line-height: 20px;
        border-radius: 7px;
        color: #fff;
        background-color: rgba(2, 167, 240, 1);
      }
    }

  }
}
</style>
